<template>
    <div>
        <div class="content" v-if="orderInfo && orderInfo.list && orderInfo.list.length !== 0">
            <div class="contentHeader flex_jcsb_ac">
                <span>亲爱的{{ userInfo.username }}，您的订单列表如下</span>
                <el-button class="elButton" type="primary" @click="createOrder">创建新订单</el-button>
            </div>
            <!-- <div class="contentHeader flex_jcsb_ac">
                <div class="orderStatus flex_ac">
                    <span>待处理订单如下</span>
                    <div class="status flex_jc_ac" v-for="item in 6" :key="item">已提交</div>
                </div>
                <div class="flex_ac">
                    <div class="input">
                        <el-input placeholder="请输入手机号码"></el-input>
                    </div>
                    <el-button class="search" type="primary">搜索</el-button>
                </div>
            </div> -->
            <div class="orderList">
                <div class="order flex" v-for="item in orderInfo.list" :key="item.id" @click="orderDetail(item)">
                    <span>订单摘要</span>
                    <div class="orderType flex_jcsb">
                        <div class="ellipsis-1">订单类型：{{ item.name }}</div>
                        <div class="ellipsis-1">订单金额：￥{{ item.price }}</div>
                    </div>
                    <div class="orderRight flex_ac">
                        <div class="orderStatus flex_jcsb">
                            <div class="steps flex">
                                <div class="step flex_ac" v-for="(val, index) in item.rate" :key="index" :class="{ active: val.active }">
                                    <div class="pencil"></div>
                                    <div class="circle"></div>
                                    <span>{{ val.text }}</span>
                                </div>
                            </div>
                            <div class="remarks flex">
                                <div class="">订单编号：{{ item.order_num }}</div>
                                <div class="note ellipsis-1"> 订单备注：{{ item.note }}</div>
                            </div>
                        </div>
                        <div class="lookDetail">
                            查看详情
                            <img src="../assets/images/goAll.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination flex_jc_ac">
                <!-- <el-button type="primary">创建新员工</el-button> -->
                <el-pagination background layout="prev, pager, next" :total="orderInfo.total" :page-size="3" @current-change="switchPage"></el-pagination>
                <!-- <el-button type="primary">录入新产品</el-button> -->
            </div>
        </div>
        <div class="content flex_jc_ac" v-else>
            <div class="noOrder">
                <div>亲爱的{{ userInfo.username }}，您还没有订单</div>
                <el-button class="elButton" type="primary" @click="createOrder">创建新订单</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'workbenches',
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  data() {
    return {
    }
  },
  methods: {
    orderDetail(item) {
      this.$router.push({
        path: '/home/orderDetail',
        query: {
          order_num: item.order_num
        }
      })
    },
    switchPage(e) {
      console.log(e)
      this.$emit('switchPage', { page: e })
    },
    createOrder() {
      this.$router.push('/home/orderFilling')
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
    flex: 1;
    margin-left: 20px;
}
.content {
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    padding: 20px;
    height: 490px;

    .noOrder {
        font-size: 18px;
      text-align: center;
      .elButton {
        margin-top: 20px;
      }
    }

    .contentHeader {
        .elButton {
            width: 180px;
            height: 42px;
        }
        .status {
            width: 84px;
            height: 25px;
            box-sizing: border-box;
            border: 1px solid #333;
            margin-left: 10px;
        }
        .search {
            width: 80px;
            height: 38px;
            margin-left: 10px;
        }
    }

    .orderList {
        width: 100%;
        height: 360px;
        margin-top: 20px;

        .order {
            width: 100%;
            height: 100px;
            border: 1px solid #333;
            margin-top: 20px;
            box-sizing: border-box;
            padding: 24px;
            cursor: pointer;

            &:first-child {
                margin-top: unset;
            }

            .orderType {
                width: 240px;
                margin-left: 12px;
                flex-direction: column;

                div {
                    &:last-child {
                        margin-top: 16px;
                    }
                }
            }
            .orderStatus {
              width: 628px;
              flex-direction: column;
              height: 100%;
            }
            .lookDetail {
                margin-left: 30px;
                img {
                  vertical-align: middle;
                  width: 6px;
                  height: auto;
                  margin-left: 8px;
                }
            }
        }
    }
}
.steps {
    .pencil {
        width: 85px;
        height: 8px;
        border-radius: 4px;
        background-color: #dcdcdc;
        margin-right: -5px;
    }
    .circle {
        width: 18px;
        height: 18px;
        background-color: #dcdcdc;
        border-radius: 50%;
    }
    span {
        font-size: 14px;
        margin-left: 4px;
        color: #dcdcdc;
    }
    .step {
        margin-right: 10px;
        &.active {
           span {
            color: #333;
           }
           .pencil, .circle {
              background-color: #abd155;
           }
        }
    }
}
.pagination {
    text-align: center;
}
</style>
