<template>
    <div class="index">
        <workbenches :orderInfo="orderInfo" @switchPage="switchPage"/>
    </div>
</template>

<script>
import workbenches from '../components/Workbenches.vue'

export default {
  name: 'Index',
  components: {
    workbenches
  },
  data() {
    return {
      page: 1,
      orderInfo: {},
      isFirstEnter: false
    }
  },
  created() {
    this.getIndex()
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'orderFilling') {
      to.meta.isBack = true
    } else if (from.name === 'orderDetail') {
      to.meta.isBack = true
    }
    next()
  },
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.page = 1
      this.orderInfo = {}
      this.getIndex()
    }
    this.$route.meta.isBack = false
    this.isFirstEnter = false
  },
  methods: {
    switchPage(e) {
      this.page = e.page
      this.getIndex()
    },
    getIndex() {
      this.$api.getIndexInfo({ page: this.page, pagesize: 3 }).then(res => {
        this.orderInfo = res
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
